@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
    box-sizing: border-box;
}

.App {
    font-family: "Roboto", sans-serif;
}

.navbar-brand,
.app-title {
    font-family: "Montserrat", sans-serif;
}

.center-all-wrapper {
    position: relative !important;
}

.center-all {
    margin: 0 !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
}

.form-container {
    width: 100% !important;
    padding-right: var(--bs-gutter-x, 0.75rem) !important;
    padding-left: var(--bs-gutter-x, 0.75rem) !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.login-container {
    width: 100% !important;
    height: 100% !important;
    padding-right: var(--bs-gutter-x, 0.75rem) !important;
    padding-left: var(--bs-gutter-x, 0.75rem) !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .form-container {
        max-width: 540px !important;
    }

    .login-container {
        max-width: 540px !important;
        max-height: 550px !important;
    }
}

@media (min-width: 768px) {
    .form-container {
        max-width: 720px !important;
    }

    .login-container {
        max-width: 380px !important;
        max-height: 390px !important;
    }

    .search {
        min-width: 500px !important;
    }
}

@media (min-width: 992px) {
    .form-container {
        max-width: 400px !important;
    }

    .login-container {
        max-width: 380px !important;
        max-height: 390px !important;
    }
}

@media (min-width: 1200px) {
    .form-container {
        max-width: 1140px !important;
    }

    .login-container {
        max-width: 380px !important;
        max-height: 390px !important;
    }
}
@media (min-width: 1400px) {
    .form-container {
        max-width: 1320px !important;
    }

    .login-container {
        max-width: 380px !important;
        max-height: 390px !important;
    }
}

.cost {
    font-size: 24px;
    color: #b12704;
}

.cart-container {
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    float: none;
    height: 39px;
    justify-content: flex-end;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    position: relative;
    width: 41px;
    font-size: 14px;
    line-height: 44px;
}

.separator {
    color: gray;
    display: flex;
    align-items: center;
    text-align: center;
}

.separator::before,
.separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid gray;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

.navbar {
    height: 60px !important;
    background-color: #2882f0;
}

.navbar-item:focus,
.navbar-item:hover {
    text-decoration: none !important;
    background-color: transparent !important;
}

.navbar-item {
    font-weight: bold;
}

.navbar-brand b {
    color: white !important;
}

.navbar-menu * {
    color: white !important;
    background-color: transparent !important;
}

.navbar-dropdown .navbar-item * {
    color: #2882f0 !important;
    font-weight: normal;
}

.navbar-dropdown .navbar-item p {
    color: black !important;
}

.navbar-dropdown .navbar-item:hover {
    background-color: rgb(211, 211, 211, 0.5) !important;
}

.search {
    color: black !important;
    border-radius: 1px;
    height: 36px;
    width: 72px;
    width: 100% !important;
    background-color: white !important;
}

.login-btn {
    background-color: white !important;
    color: #2882f0 !important;
    width: 120px !important;
    border-radius: 1px !important;
}

.user-control {
    margin-left: 10px !important;
}

.fa-icon * {
    color: #2882f0 !important;
}

.overlay-wrapper {
    display: block;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    position: absolute !important;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    color: white;
}

.overlay {
    max-width: 750px !important;
    max-width: 650px !important;
    min-height: 750px !important;
    max-height: 850px !important;
    overflow: hidden;
    width: 100% !important;
    height: 100% !important;
    padding: 30px !important;
}

html,
body {
    padding: 0 !important;
    margin: 0 !important;
    overflow-y: auto;
}

html {
    height: 100% !important;
}

body {
    background: rgb(211, 211, 211, 0.2) !important;
    min-height: 100% !important;
}

#root > div > main > nav > div > div.navbar-menu > div.navbar-end > div > div {
    opacity: 10 !important;
    background-color: white !important;
    z-index: 10 !important;
}

/* .MuiButtonBase-root {
    background-color: rgb(40, 116, 240) !important;
} */

input:-webkit-autofill {
    -webkit-background-clip: text;
    background-clip: text;
    -moz-background-clip: text;
}
